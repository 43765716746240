import React from 'react';
import { Area, Bar, Rectangle, Line } from 'recharts';
import moment from 'moment';

class ChartBase extends React.Component {
  constructor(props) {
    super(props);

    this.buildChartData = this.buildChartData.bind(this);
    this.buildChartSettings = this.buildChartSettings.bind(this);
    this.renderChart = this.renderChart.bind(this);
    this.renderBar = this.renderBar.bind(this);
    this.renderBand = this.renderBand.bind(this);
    this.renderLine = this.renderLine.bind(this);
    this.tickFormatter = this.tickFormatter.bind(this);
  }

  buildChartSettings(row) {
    const { filters, distinct_properties } = this.props.warehouse;
    const { propertyFields, groupFields } = this.props.trend_analysis;

    const filterGroups = filters.group_id;
    const { members } = this.props.analysis_groups;

    const benchmarkGroups = members?.rows.filter(member => filterGroups?.includes(member.analysis_group_id));

    const settings = {
      leftAxis: {
        title: null,
        metric_id: null,
        row,
        yAxis: 'left',
        properties: [],
        groups: []
      },
      rightAxis: {
        title: null,
        metric_id: null,
        row: {},
        yAxis: 'right',
        properties: [],
        groups: []
      }
    };

    settings.leftAxis.metric_id = row.metric_id;
    settings.leftAxis.title = `${row.group?.caption} - ${row.metric?.name}`;
    settings.leftAxis.unit_attributes = row.metric?.unit_attributes;

    settings.leftAxis.properties = filters.property_id.map(property_id => {
      const property = distinct_properties.find(distinct_property => distinct_property.id === property_id);
      return {
        id: property_id,
        name: property?.name,
        fields: propertyFields
      };
    });

    settings.leftAxis.groups = benchmarkGroups.map(benchmarkGroup => (
      {
        id: benchmarkGroup.id,
        name: benchmarkGroup?.group.name,
        fields: groupFields
      }
    ));

    return settings;
  }

  buildChartData(settings) {
    const { probeRows, probeGroups, groupsMinMax } = this.props.warehouse;
    const templateRow = settings?.leftAxis?.row;
    // const options = {maximumFractionDigits: 2};

    // Filter to row metric only
    const filteredRows = probeRows.filter(row => row.kpigroup_id ===  templateRow?.group_id && row.metric_id === templateRow?.metric_id);

    const chartData = filteredRows.map(row => {
      // Find group
      const probeGroupRow = probeGroups.find(probeGroup => probeGroup.metric_id === row.metric_id && probeGroup.kpigroup_id === row?.kpigroup_id && probeGroup.period_id === row?.period_id);
      const benchmark_top20 = probeGroupRow?.benchmark_top20?.data;
      const benchmark_1yr_average = probeGroupRow?.benchmark_1yr_average?.data;

      const minMaxGrpAvg = groupsMinMax.find(groupMinMax => groupMinMax.metric_id === row.metric_id && groupMinMax.kpigroup_id === row?.kpigroup_id && groupMinMax.period_id === row?.period_id && groupMinMax.tag === 'benchmark_1yr_average');

      const chartElement = {
        name: row.period.probe_period,
        to_date: row.period.date,
        metric_name: templateRow.metric.name,
        property_name: row.property.name,
        client_entered: row.client_entered?.data?.value,
        benchmark_values: row.client_benchmark_values?.data?.value,
        benchmark_top20,
        benchmark_1yr_average,
        minMaxGrpAvg: [minMaxGrpAvg?.minValue, minMaxGrpAvg?.maxValue],
      };

      return chartElement;
    });

    // Sort chart by date
    chartData.sort((left, right) => new Date(left.to_date) - new Date(right.to_date));

    this.setState({chartData});

    return chartData;
  }

  renderBar(axis, settings) {
    return (
      <Bar
        yAxisId={axis}
        // maxBarSize={30}
        barSize={20}
        // barGap={13}
        // barCategoryGap={200}
        onClick={this.onClick}
        name={settings.name}
        dataKey={settings.dataKey}
        fill={settings.colour}
        activeBar={<Rectangle fill="pink" stroke="blue" />}
        key={settings.dataKey}
      />
    );
  }

  renderLine(axis, settings) {
    return (
      <Line
        yAxisId={axis}
        name={settings.name}
        type="monotone"
        dataKey={settings.dataKey}
        stroke={settings.colour}
        strokeWidth={3}
        activeDot={{ onClick:this.onClick }}
        connectNulls
        key={settings.dataKey}
      />
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderBand(axis, settings) {
    return (
      <Area
        type="monotone"
        dataKey={settings.dataKey}
        stroke="none"
        fill={settings.colour}
        connectNulls
        dot={false}
        activeDot={false}
        yAxisId={axis}
        name={settings.name}
      />
    );
  }

  renderChart() {
    const { settings } = this.state;

    // Render properties
    const chartElement = [];
    settings?.leftAxis?.properties?.map(property => {
      property.fields.map(field => {
        switch (field.chart) {
          case 'Bar':
            chartElement.push(this.renderBar('left', field));
          break;
          case 'Line':
            chartElement.push(this.renderLine('left', field));
          break;
          default: break;
        }
      });
    });

    // Render Benchmark Groups
    settings?.leftAxis?.groups?.map(group => {
      group.fields.map(field => {
        switch (field.chart) {
          case 'Bar':
            chartElement.push(this.renderBar('left', field));
          break;
          case 'Line':
            chartElement.push(this.renderLine('left', field));
          break;
          case 'Band':
            chartElement.push(this.renderBand('left', field));
          break;
          default: break;
        }
      });
    });

    return chartElement;
  }

  // eslint-disable-next-line class-methods-use-this
  tickFormatter(value, unit_attributes) {
    const options = {maximumFractionDigits: unit_attributes?.decimals};
    return `${unit_attributes?.prefix?.replace(' ', '') || ''}${value.toLocaleString(undefined, options)}${unit_attributes?.suffix?.replace(' ', '') || ''}`;
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return (
     <React.Fragment></React.Fragment> 
   );
 }
}

export default ChartBase;

